<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Property Matching</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Property Matching"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              light
              :to="{ name: 'module-melrose-properties-property-matching' }"
            >
              <v-icon small left>mdi-arrow-left</v-icon> Back to Property
              Matching
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="property_matching"
        class="mt-4"
        no-data-text="No Property Matching found"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="yellow lighten-4 yellow--text text--darken-3"
                v-on="on"
                @click="openRestore(item)"
              >
                <v-icon small>mdi-restore</v-icon>
              </v-btn>
            </template>
            <span>Restore</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog v-model="restoreDialog.open" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">Restore Submission</v-card-title>
          <v-card-text>Are you sure you want to restore this?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetRestore"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="restoreDialog.loading"
              @click="saveRestore"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      restoreDialog: {
        open: false,
        loading: false,
        note_of_interest: {},
      },
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          exact: true,
          to: { name: "module-melrose-properties-all" },
        },
        {
          text: "Property Matching",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Full Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Property Type", value: "property_type" },
        { text: "Price Min", value: "min_price" },
        { text: "Price Max", value: "max_price" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    property_matching() {
      let submissions = this.$store.state.contactForms["archivedSubmissions"];

      submissions = submissions.filter((slot) => {
        return slot.form_name == "matching-property-form";
      });

      submissions.forEach((slot) => {
        slot.min_price = slot.content["Min Price"];
        slot.max_price = slot.content["Max Price"];
        slot.property_type = slot.content["Property Type"];
      });

      if (this.searchTerm !== "") {
        submissions = submissions.filter((s) => {
          const name = s.full_name.toLowerCase();
          const email = s.email.toLowerCase();
          const formName = s.form_name.toLowerCase();
          const pageUrl = s.page_url.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            name.includes(searchTerm) ||
            email.includes(searchTerm) ||
            formName.includes(searchTerm) ||
            pageUrl.includes(searchTerm)
          );
        });
      }

      return submissions;
    },
  },

  methods: {
    openRestore(submission) {
      this.restoreDialog.submission = submission;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.submission = {};
    },

    saveRestore() {
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("contactForms/restoreSubmission", {
          appId: this.$route.params.id,
          submissionId: this.restoreDialog.submission.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
